/*eslint no-new: "error"*/
<template>
  <div >
    <div class="flix-col-md-12 flix-text-center" v-if="filled">
      <h2>Danke für das Feedback!</h2>
    </div>
    <div :class="getClass()">
      <div ref="hitklickContainer" ></div>
    </div>
  </div>
</template>
<script>
import { getHitklick } from '@/components/bookingInfoPage/components/hitklick/getHitklick.js'
export default {
  components: {},
  props: {
    data: Object
  },
  mounted () {
    this.checkEntry()
  },
  computed: {

  },
  data () {
    return {
      code: false,
      filled: false
    }
  },
  methods: {
    getClass () {
      if (this.filled) {
        return 'flix-col-md-4 flix-col-md-flix-offset-4 flix-col-sm-6 flix-col-sm-offset-3 flix-col-xs-12 flix-col-xs-offset-0'
      }
      return 'flix-col-md-12 flix-col-xs-12'
    },
    checkEntry () {
      this.$flix_post({
        url: 'hitclick/feedback/check_by_booking_id',
        data: {
          booking_id: this.data.booking_id
        },
        callback: function (ret) {
          this.filled = false
          if (ret.data[0]) {
            this.filled = true
          }
          this.getData(ret.data[2], function () { this.getHitklick(ret.data[0]) }.bind(this))
        }.bind(this)
      })
    },

    getCustom (data) {
      return {
        language: 'de',
        board: data.survey * 1,
        user: data.username,
        embed: ['board'],
        colors: {
          text_color: '#666666',
          background_color: '#f7f7f7',
          accent_color: '#8DC044',
          accent_color2: '#ffffff'
        },
        width: ['12'],
        design: {
          badge: 'Maria',
          survey: 'Alphonso',
          votings: 'Table'
        }
      }
    },

    getDefault () {
      var embed = 'eyJsYW5ndWFnZSI6ImRlIiwiYm9hcmQiOiI0NSIsInVzZXIiOiJhZG1pbiIsImVtYmVkIjpbImJvYXJkIl0sImNvbG9ycyI6eyJ0ZXh0X2NvbG9yIjoiIzY2NjY2NiIsImJhY2tncm91bmRfY29sb3IiOiIjZmZmZmZmIiwiYWNjZW50X2NvbG9yIjoiIzY2NjY2NiIsImFjY2VudF9jb2xvcjIiOiIjZmZmZmZmIn0sIndpZHRoIjpbIjEyIl0sImRlc2lnbiI6eyJiYWRnZSI6IkFscGhvbnNvIiwic3VydmV5IjoiQWxwaG9uc28iLCJ2b3RpbmdzIjoiVGFibGUifX0='
      return JSON.parse(atob(embed))
    },

    getData (ret, callback) {
      if (ret.type === 'none') {
        this.code = false
      } else if (ret.type === 'custom') {
        this.code = this.getCustom(ret)
      } else {
        this.code = this.getDefault()
      }
      callback()
    },

    getHitklick (bool) {
      var embed = this.code

      if (!embed) {
        return false
      }

      if (bool) {
        embed.embed = ['badge', 'feedback']
        embed.width = [3, 9]
        embed.embed = ['badge']
        embed.width = [3]
      } else {
        embed.save = {
          name: this.$tc('message.customer', 1) + ' ' + this.data.booking_id,
          email: this.data.email
        }
      }

      embed.colors.accent_color = '#8DC044'
      embed.design.badge = 'Alphonso'

      embed = btoa(JSON.stringify(embed))

      var div = document.createElement('div')
      div.setAttribute('ref', 'hitklick')

      this.$refs.hitklickContainer.appendChild(div)
      if (typeof hit === 'undefined') {
        /*eslint-disable */
        var hit2 = new getHitklick(function (f, e) { var h = new hit(f, e); window.console.log(Object.keys(h).length) })
        /* eslint-enable */
        hit2.run(embed, div)
      } else {
        /*eslint-disable */
        var h = new hit(div, embed)
      /* eslint-enable */
        window.console.log(Object.keys(h).length)
      }
    }

  }
}
</script>
<style lang="sass">
  .hit-container
    width: inherit !important
</style>
