var getHitklick = function (callback) {
  this.run = function (embed, ref) {
    this.ref = ref

    this.loadCalendar(
      window,
      document,
      'script',
      'https://api.hitklick.com/assets/js/embed.js',
      embed
    )
  }

  this.loadCalendar = function (a, b, c, d, e, f, g) {
    f = b.createElement(c)
    g = this.ref
    f.async = 1
    f.src = d
    g.parentNode.replaceChild(f, g, b)
    f.onload = function () { callback(f, e) }
  }

  this.checkSurveyType = function (data) {
    if (typeof data.survey === 'undefined' || typeof data.survey.type === 'undefined') {
      return 'Default'
    }
    if (data.survey.type === 'custom') {
      return 'Custom'
    }

    return false
  }
}

export { getHitklick }
